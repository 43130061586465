import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-confirm-sale-ticket',
  templateUrl: './dialog-confirm-sale-ticket.component.html',
  styleUrls: ['./dialog-confirm-sale-ticket.component.scss']
})
export class DialogConfirmSaleTicketComponent implements OnInit {
  tickets: any;
  informationAccount: any;
  ticketId: any;
  languageKey: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DialogConfirmSaleTicketComponent>) {
    this.languageKey = data.languageKey;
    this.tickets = data.tickets;
  }

  ngOnInit(): void {}

  /**
   * closeDialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayDate(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 16).replace('T', ' ');
  }

  /**
   * getStatus
   * @param input
   * @returns
   */
  getStatus(input: string) {
    if (!input && input != '0') {
      return;
    }
    const status = {
      '0': '販売開始前',
      '1': '販売中',
      '2': '販売停止中',
      '3': '販売終了'
    };
    return status[input];
  }
}
