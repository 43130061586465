import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-confirm-with-multiple-messages',
  templateUrl: './dialog-confirm-with-multiple-messages.component.html',
  styleUrls: ['./dialog-confirm-with-multiple-messages.component.scss']
})
export class DialogConfirmWithMultipleMessagesComponent implements OnInit {
  itemsArray: { key: string; value: string }[] = [];
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmWithMultipleMessagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.itemsArray = Array.from(this.data.items, ([key, value]) => ({ key, value }));
  }
}

export interface DialogData {
  title: string;
  message: string;
  items: Map<string, string>;
  button1: string;
  button2: string;
  button3?: boolean;
}
