import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { BackgroundColorModeEnum, Constant } from 'app/config/constants';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';

@Component({
  selector: 'dialog-background-color-setting',
  templateUrl: './dialog-background-color-setting.component.html',
  styleUrls: ['./dialog-background-color-setting.component.scss']
})
export class DialogBackgroundColorSettingComponent implements OnInit {
  commonTable: CommonTable;
  backgroundColor: string;

  constructor(
    private dialogRef: MatDialogRef<DialogBackgroundColorSettingComponent>,
    private commonTableService: CommonTableService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.backgroundColor = this.data.commonTable.value ? JSON.parse(this.data.commonTable.value) : BackgroundColorModeEnum.LIGHT;
    this.commonTable = _.cloneDeep(this.data.commonTable)
  }

  save(): void {
    this.commonTable.value = JSON.stringify(this.backgroundColor);
    this.commonTableService.save(this.commonTable).subscribe(
      res => {
        this.dialogRef.close(this.backgroundColor)
      }, 
      error => Helper.handleError(error, this.translateService, this.dialogService)
    )
  }
}
export interface DialogData {
  commonTable: CommonTable;
}