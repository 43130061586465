import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { APICycle } from 'app/model/entity/api-cycle';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-call-api-cycle',
  templateUrl: './dialog-call-api-cycle.component.html',
  styleUrls: ['./dialog-call-api-cycle.component.scss']
})
export class DialogCallApiCycleComponent implements OnInit {
  readonly FORMAT_TIME = 'HH:mm';

  Constant = Constant;

  apiCycle: APICycle;

  /**
   * config
   */
  config: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    minutesInterval: 10,
    hours24Format: 'HH'
  };

  lastMinuteTo: number;
  lastHourTo: number;
  lastMinuteFrom: number;
  lastHourFrom: number;
  /**
   * is invalid interval
   */
  isInvalidInterval: boolean = false;
  numberConvert: any;
  isTypeKeyBoard = false;
  msgInterval: any;
  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * language key
   */
  public languageKey: string;

  /**
   * common object
   */
  private commonObject: Common;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogCallApiCycle,
    private dialogRef: MatDialogRef<DialogCallApiCycleComponent>,
    private commonTableService: CommonTableService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private commonService: CommonService
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  configTime: any = {
    format: this.FORMAT_TIME,
    unSelectOnClick: false,
    hideOnOutsideClick: true,
    showTwentyFourHours: true,
    hours24Format: 'HH'
  };

  ngOnInit(): void {
    this.apiCycle = JSON.parse(this.data.commonTable.value);
    this.apiCycle.startTime = !this.apiCycle.startTime ? Constant.TMP_TIME_NULL : this.apiCycle.startTime;
    this.apiCycle.endTime = !this.apiCycle.endTime ? Constant.TMP_TIME_NULL : this.apiCycle.endTime;
    let timeTmpFrom = [];
    let timeTmpTo = [];
    timeTmpFrom = this.apiCycle.startTime.split(':');
    timeTmpTo = this.apiCycle.endTime.split(':');
    this.lastHourTo = timeTmpTo[0];
    this.lastMinuteTo = timeTmpTo[1];
    this.lastHourFrom = timeTmpFrom[0];
    this.lastMinuteFrom = timeTmpFrom[1];
    this.msgInterval = <HTMLElement>document.getElementById('msgInterval');
    this.numberConvert = _.cloneDeep(this.apiCycle.interval);
    this.languageKey = this.commonObject?.setting?.language;
  }

  /**
   * change time
   * @param data
   * @param type
   */
  public changeTime(data, type: any): void {
    if (typeof data == 'object') {
      return;
    }
    let lastHour, lastMinute;
    if (type == 'end') {
      lastHour = this.lastHourTo != undefined ? this.lastHourTo : Constant.TMP_TIME_NULL;
      lastMinute = this.lastMinuteTo != undefined ? this.lastMinuteTo : Constant.TMP_TIME_NULL;
    } else {
      lastHour = this.lastHourFrom != undefined ? this.lastHourFrom : Constant.TMP_TIME_NULL;
      lastMinute = this.lastMinuteFrom != undefined ? this.lastMinuteFrom : Constant.TMP_TIME_NULL;
    }
    let tmpTime;
    tmpTime =
      typeof data == 'object'
        ? moment(data._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : data.split(':');
    let currentMinute = parseInt(tmpTime[1]);
    let currentHour = parseInt(tmpTime[0]);
    let newHour, newMinute;
    if (currentHour != lastHour && currentMinute != lastMinute) {
      newHour = lastHour;
      newMinute = currentMinute;
      if (type == 'end') {
        this.apiCycle.endTime = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      } else {
        this.apiCycle.startTime = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;
      }
      this.cdr.detectChanges();
    } else {
      newHour = currentHour;
      newMinute = currentMinute;
    }
    if (type == 'end') {
      this.lastHourTo = newHour;
      this.lastMinuteTo = newMinute;
    } else {
      this.lastHourFrom = newHour;
      this.lastMinuteFrom = newMinute;
    }
  }

  /**
   * on submit
   */
  onSubmit() {
    this.apiCycle.startTime = Helper.convertFromMomentToTime(this.apiCycle.startTime);
    this.apiCycle.endTime = Helper.convertFromMomentToTime(this.apiCycle.endTime);
    if (this.isInvalidWhenSaveAPICycle()) {
      return;
    }
    if (this.apiCycle.endTime == '00:00') {
      this.apiCycle.endTime = '24:00';
    }
    let apiCycle = new APICycle(this.apiCycle.startTime, this.apiCycle.endTime, this.apiCycle.interval);
    this.data.commonTable.value = JSON.stringify(apiCycle);
    this.commonTableService.save(this.data.commonTable).subscribe(result => {
      this.dialogRef.close(result);
    });
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * is invalid when save api cycle
   * @returns
   */
  public isInvalidWhenSaveAPICycle(): boolean {
    let isInvalid = false;

    if (this.checkStartTimeGreaterEndTime(this.apiCycle.startTime, this.apiCycle.endTime)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-call-api-cycle.error'),
          text: this.translateService.instant('dialog-call-api-cycle.msg.end-time-greater')
        }
      });
      isInvalid = true;
    }
    if (!this.apiCycle.interval) {
      this.msgInterval.innerHTML = this.translateService.instant('dialog-call-api-cycle.msg.interval-empty');
      isInvalid = true;
    }
    if (this.apiCycle.interval && this.isInvalidInterval) {
      this.msgInterval.innerHTML = this.translateService.instant('dialog-call-api-cycle.msg.current-length');
      isInvalid = true;
    }
    return isInvalid;
  }

  /**
   * change interval
   * @param data
   */
  public async changeInterval(data) {
    if (data < 1) {
      this.apiCycle.interval = 24;
    } else if (data > 24) {
      this.apiCycle.interval = 1;
    } else {
      return;
    }
  }

  doSomething(data: any) {
    this.isTypeKeyBoard = true;
  }
  /**
   * check start time greater end time
   * @param startTime
   * @param endTime
   * @returns
   */
  public checkStartTimeGreaterEndTime(startTime: any, endTime: any): boolean {
    let valueStart = Helper.convertTime(startTime);
    let valueEnd = Helper.convertTime(endTime);
    let isInvalid: boolean = false;
    if (valueStart >= valueEnd && valueEnd != 0) {
      isInvalid = true;
    }
    return isInvalid;
  }
}

export interface DialogCallApiCycle {
  commonTable: CommonTable;
}
