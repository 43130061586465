import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { City } from 'app/model/entity/city';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dialog-choose-city',
  templateUrl: './dialog-choose-city.component.html',
  styleUrls: ['./dialog-choose-city.component.scss']
})
export class DialogChooseCityComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogChooseCityData,
    private dialogRef: MatDialogRef<DialogChooseCityData>,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}
  /**
   * max display setting
   */
  public readonly MAX_DISPLAY_SETTING = 8;
  cityListOfContent: Array<City>;
  cityListFromImport: Array<City>;

  ngOnInit(): void {
    this.setDataToDialog();
  }

  /**
   * set data to dialog
   */
  public setDataToDialog() {
    this.cityListFromImport = _.cloneDeep(this.data.cityListFromImport);
    this.cityListOfContent = _.cloneDeep(this.data.cityListOfContent);
    if (!this.cityListOfContent || (this.cityListOfContent && this.cityListOfContent?.length == 0)) {
      this.cityListOfContent = [];
      this.cityListOfContent.push(new City(-1));
    }
  }

  /**
   * add new row
   * @returns
   */
  public addRow(): void {
    if (this.cityListOfContent.length == this.MAX_DISPLAY_SETTING) {
      return;
    }
    if (this.cityListOfContent.length == 0) {
      let idCity = +(<HTMLInputElement>document.getElementById('city1')).value;
      let t = this.cityListFromImport.find(e => e.id == idCity) ? this.cityListFromImport.find(e => e.id == idCity) : new City(-1);
      this.cityListOfContent.push(t);
    }
    this.cityListOfContent.push(new City(-1));
  }

  /**
   * save
   */
  public save(): void {
    let maxValue = -1;
    let arrCheck = _.cloneDeep(this.cityListOfContent);
    for (let i = 0; i < arrCheck.length; i++) {
      if (arrCheck[i].id != -1) {
        let cityTemp = arrCheck[i];
        if (cityTemp.id) {
          maxValue = i;
        }
        cityTemp = this.cityListFromImport.find(e => e.id == cityTemp.id);
        arrCheck[i] = cityTemp;
      }
    }
    for (let i = 0; i < arrCheck.length; i++) {
      if (arrCheck[i]?.id == -1 && i < maxValue) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('dialog-choose-city.msg.error-selection')
          }
        });
        return;
      }
    }
    this.dialogRef.close(arrCheck.filter(e => e.id != -1));
  }

  /**
   * close
   */
  public close(): void {
    this.dialogRef.close(undefined);
  }

  /**
   * check duplicate
   * @param value
   * @returns
   */
  checkDuplicate(value) {
    if (value == -1) {
      return false;
    }
    return this.cityListOfContent.find(e => e?.id == value);
  }
}

export interface DialogChooseCityData {
  cityListOfContent: Array<City>;
  cityListFromImport: Array<City>;
}
