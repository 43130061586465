import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { DialogService } from 'app/service/dialog.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';

@Component({
  selector: 'dialog-confirm-delete-reservation',
  templateUrl: './dialog-confirm-delete-reservation.component.html',
  styleUrls: ['./dialog-confirm-delete-reservation.component.scss']
})
export class DialogConfirmDeleteReservationComponent implements OnInit {
  reservationDetail: any;
  languageKey: any;
  languageCode: any;
  helper = Helper;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogConfirmDeleteReservationComponent>,
    private dialogService: DialogService,
    private ticketManagerService: TicketManagerService
  ) {
    if (data.languageKey) {
      this.languageKey = data.languageKey;
      this.languageCode = this.languageKey == 'en' ? 'en' : 'ja';
    }
    if (data.reservationDetail) {
      this.reservationDetail = data.reservationDetail;
    }
  }

  ngOnInit(): void {}

  /**
   * deleteReservation
   */
  deleteReservation() {
    //Delete
    const payload = {
      reservationId: this.reservationDetail.reservationId
    };
    this.ticketManagerService.deleteReservation(payload).subscribe(
      data => {
        if (data.result) {
          const result = {
            reservationId: data.reservationId,
            result: data.result
          };
          this.dialogRef.close(result);
        }
      },
      error => {
        this.dialogRef.close();
      }
    );
  }

  /**
   * close
   */
  close(): void {
    this.dialogService.closeAllDialog();
  }

  /**
   * displayDateTime
   * @param time
   * @returns
   */
  displayDateTime(time: string): string {
    // Tách các thành phần thời gian từ chuỗi
    //2024-06-30T09:00:00+09:00
    if (!time) {
      return '';
    }
    const dateHours = time.split('T');
    const date = dateHours[0].split('-');
    const year = date[0];
    const month = date[1];
    const day = date[2];
    const newTime = dateHours[1];
    const hour = newTime.substring(0, 2);
    const minute = newTime.substring(3, 5);

    // Ghép lại các thành phần theo định dạng mong muốn
    const formattedDateTime = `${year}年${month}月${day}日  ${hour}:${minute}`;
    return formattedDateTime;
  }

  /**
   * changeDisplayStatus
   * @param status
   */
  changeDisplayStatus(status: string): string {
    if (!status) {
      return;
    }
    if (status == 'reserved') {
      return '収受済み';
    } else {
      return '未収受';
    }
  }
}
