import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusStop } from '../../model/entity/bus-stop';
import { IndexWord } from '../../model/entity/index-word';
import { IndexWordService } from '../../service/index-word.service';
import { IndexWordGroup } from '../../model/entity/index-word-group';
import { DialogService } from '../../service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { Constant } from '../../config/constants';
import { BusStopService } from 'app/service/bus-stop.service';

@Component({
  selector: 'app-dialog-bus-stop-detail',
  templateUrl: './dialog-bus-stop-detail.component.html',
  styleUrls: ['./dialog-bus-stop-detail.component.scss']
})
/**
 * Component class for Bus Stop Detail Dialog
 */
export class DialogBusStopDetailComponent implements OnInit {
  /**
   * list of all index words from selected groups
   */
  indexWords: Array<IndexWord>;
  /**
   * list of index words from selected group of selected bus stop
   */
  selectedIndexWords: Array<IndexWord>;
  /**
   * dialog title
   */
  title: string;

  /**
   * constructor
   * @param dialogRef reference to an opened dialog
   * @param data dialog data
   * @param indexWordService service for fetching index word list
   */
  constructor(
    private dialogRef: MatDialogRef<DialogBusStopDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private indexWordService: IndexWordService,
    private dialogService: DialogService,
    private busStopService: BusStopService
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.selectedIndexWords = this.data.busStop.displayIndexWords
      ?.filter(indexWord => indexWord.id != 0)
      .map(indexWord => Object.assign({}, indexWord));
    this.indexWordService.getIndexWords().subscribe(
      result => {
        if (this.data.selectedIndexWordGroup) {
          this.indexWords = result
            .filter(indexWord => indexWord.groupId == this.data.selectedIndexWordGroup.id)
            .sort((indexWord1, indexWord2) => {
              return <number>indexWord1.id - <number>indexWord2.id;
            });
          this.indexWords.push(
            new IndexWord(this.data.selectedIndexWordGroup.id as number, 'None', '', -this.data.selectedIndexWordGroup.id)
          );
        }
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  isChecking: boolean = false;
  /**
   * save bus stop data and close dialog
   */
  saveBusStop() {
    if (this.isChecking) {
      return;
    }
    let stopNo = this.data.busStop.no.trim();
    if (stopNo.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Bus Stop No. cannot be empty.' } });
      return;
    }
    if (stopNo.length > 5) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Bus Stop No. must contain no more than 5 characters.' }
      });
      return;
    }
    this.data.busStop.no = stopNo;
    let stopSuffix = this.data.busStop.suffix.trim();
    if (stopSuffix.length > 3) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Suffix must contain no more than 3 characters.' }
      });
      return;
    }
    this.data.busStop.suffix = stopSuffix;
    let stopNameValue = this.data.busStop.name.trim();
    if (stopNameValue.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Bus Stop Name cannot be empty.' } });
      return;
    }
    if (stopNameValue.length > 48) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: { title: 'Error', text: 'Bus Stop Name must contain no more than 48 characters.' }
      });
      return;
    }
    this.data.busStop.name = stopNameValue;
    // check duplicate bus stop no and suffix
    this.isChecking = true;
    this.busStopService.checkExist(stopNo, stopSuffix, this.data.busStop.id ?? null).subscribe(
      busStop => {
        if (busStop) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Duplicated bus stop ID.' } }, () => {
            this.isChecking = false;
          });
          return;
        }
        if (this.data.selectedIndexWordGroup) {
          this.selectedIndexWords.forEach(indexWord => (indexWord.name = indexWord.id < 0 ? '' : indexWord.name));
          this.data.busStop.displayIndexWords = this.selectedIndexWords;
          for (let i = 0; i < this.data.busStop.displayIndexWords.length; i++) {
            this.data.busStop.displayIndexWords[i] = Object.assign({}, this.data.busStop.displayIndexWords[i]);
            this.data.busStop.displayIndexWords[i].orderNo = i;
          }
          this.data.busStop.indexWords = this.data.busStop.displayIndexWords.concat(
            this.data.busStop.indexWords.filter(indexWord => indexWord.groupId != this.data.selectedIndexWordGroup.id)
          );

          while (this.data.busStop.displayIndexWords.length < Constant.MAX_INDEX_WORD) {
            this.data.busStop.displayIndexWords.push(new IndexWord(this.data.selectedIndexWordGroup.id as number, '', '', 0));
          }
        }
        this.data.busStop.isChecked = false;
        this.dialogRef.close({ busStop: this.data.busStop });
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * remove index word from bus stop
   * @param indexWord index word to remove
   */
  removeIndexWord(index: number) {
    this.selectedIndexWords.splice(index, 1);
  }

  /**
   * change index word
   * @param indexWordId index word ID
   * @param index index in index word list
   */
  changeIndexWord(indexWordId: number, index: number) {
    this.selectedIndexWords[index] = this.indexWords.find(indexWord => indexWord.id == indexWordId);
  }

  /**
   * add a new index word
   */
  addIndexWord() {
    if (this.selectedIndexWords.length >= Constant.MAX_INDEX_WORD || !this.data.selectedIndexWordGroup) {
      return;
    }
    this.selectedIndexWords.push(
      new IndexWord(this.data.selectedIndexWordGroup.id as number, 'None', '', -this.data.selectedIndexWordGroup.id)
    );
  }
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  busStop: BusStop;
  selectedIndexWordGroup: IndexWordGroup;
}
