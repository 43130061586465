import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Timetable } from 'app/model/entity/schedule/timetable';
import { DialogService } from 'app/service/dialog.service';
import { ScheduleMergeService } from 'app/service/schedule-merge.service';
@Component({
  selector: 'dialog-create-daily-schedule',
  templateUrl: './dialog-create-daily-schedule.component.html',
  styleUrls: ['./dialog-create-daily-schedule.component.scss']
})
export class DialogCreateDailyScheduleComponent implements OnInit {
  /**
   * loading
   */
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogCreateDailyScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogService: DialogService,
    private scheduleMergeService: ScheduleMergeService,
    private translateService: TranslateService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.handleCreateData();
  }

  /**
   * handle Download Data
   */
  private handleCreateData(): void {
    if (!this.data.timetableSelected) {
      return;
    }
    let timetablesDaily;
    this.scheduleMergeService.createDailySchedule(this.data.timetableSelected.id, this.data.areaGroupName).subscribe(data => {
      timetablesDaily = Helper.convertTimetableDaily(data, this.data.timeDateLine);
      this.setInvalidToTimetablesDaily(timetablesDaily);
      this.loading = false;
      this.dialogRef.close(timetablesDaily);
    });
  }
  /**
   * setInvalidToTimetablesDaily
   */
  private setInvalidToTimetablesDaily(timetablesDaily: any) {
    if (!this.data.duplicateSetting || Object.keys(this.data.duplicateSetting).length === 0 || this.data.duplicateSetting.column1 == '-1') {
      return;
    }
    const indexColumn1Valid = this.data.duplicateSetting.column1;
    const indexColumn2Valid = this.data.duplicateSetting.column2;
    const indexColumn3Valid = this.data.duplicateSetting.column3;
    timetablesDaily.forEach(timetable => {
      const times = timetable?.schedules?.map(schedule => {
        return {
          time: schedule.columnsData[indexColumn1Valid],
          valueColumn: schedule.columnsData[indexColumn2Valid]
        };
      });
      const timesTeml = [];
      const timesInvalid = [];
      if (times && times.length > 0) {
        for (const time of times) {
          const index = timesTeml.findIndex(e => this.isEqual(e, time));
          if (index != -1) {
            timesInvalid.push(time);
          } else {
            timesTeml.push(time);
          }
        }
      }
      const isObjectInArray = (arr: any[], obj: any): boolean => {
        return arr.some(item => this.isEqual(item, obj));
      };
      timetable?.schedules?.forEach(schedule => {
        if (
          timesInvalid.length &&
          isObjectInArray(timesInvalid, {
            time: schedule.columnsData[indexColumn1Valid],
            valueColumn: schedule.columnsData[indexColumn2Valid]
          })
        ) {
          const schedulesTempl = timetable.schedules.filter(data => {
            const column0Matches = data.columnsData[indexColumn1Valid] == schedule.columnsData[indexColumn1Valid];
            const column2Matches = data.columnsData[indexColumn2Valid] == schedule.columnsData[indexColumn2Valid];
            const column3Valid =
              indexColumn3Valid &&
              indexColumn3Valid != '-1' &&
              this.data.duplicateSetting.column4 &&
              data.columnsData[indexColumn3Valid].trim() != this.data.duplicateSetting.column4 &&
              data.columnsData[indexColumn3Valid].trim() != Helper.reEncodeHTML(this.data.duplicateSetting.column4);

            return indexColumn3Valid && indexColumn3Valid != '-1'
              ? column0Matches && column2Matches && column3Valid
              : column0Matches && column2Matches;
          });
          if (schedulesTempl.length > 1) {
            schedulesTempl.forEach(item => {
              item.inValidRowDulicate = true;
              timetable.inValidRow = true;
            });
          }
        }
      });
    });
  }

  /**
   * isEqual
   * @param obj1
   * @param obj2
   * @returns
   */
  private isEqual(obj1: any, obj2: any): boolean {
    return obj1.time === obj2.time && obj1.valueColumn === obj2.valueColumn;
  }

  priva;
}

/**
 * export dialog data
 */
export interface DialogData {
  timetableSelected: Timetable;
  timeDateLine: any;
  duplicateSetting: any;
  areaGroupName: string;
}
