import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';

@Component({
  selector: 'app-dialog-custom-sort',
  templateUrl: './dialog-custom-sort.component.html',
  styleUrls: ['./dialog-custom-sort.component.scss']
})
export class DialogCustomSortComponent implements OnInit {
  /**
   * list property sorted
   */
  listSortedProperty: Array<String> = new Array<String>();
  /**
   * list type sorted
   */
  listSortedType: Array<String> = new Array<String>();
  /**
   * list sorted
   */
  arraySorted: Array<String> = new Array<String>();
  /**
   * list option property sort
   */
  listProperty: Array<header> = new Array<header>();
  /**
   * list option sort type
   */
  listSortType = ['ASC', 'DESC'];
  /**
   * list old sorted
   */
  listSorted: any;
  /**
   * row selected
   */
  rowSelected: number;

  @ViewChild('customSort') private customSort: ElementRef;

  /**
   * constructor
   * @param dialogRef reference to an opened dialog
   * @param data dialog data
   */
  constructor(private dialogRef: MatDialogRef<DialogCustomSortComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
    this.listProperty = this.data.list[1];
    if (this.data.list[0]) {
      this.listSorted = this.data.list[0];
      for (let i = 0; i < this.listSorted[0]?.length; i++) {
        this.listSortedProperty.push(this.listSorted[0][i]);
        this.listSortedType.push(this.listSorted[1][i]);
        this.arraySorted.push('1');
      }
    }

    if (this.listSorted.length == 0) {
      this.listProperty.map(property => (property.isChosen = false));
    }
  }

  /**
   * add a new select box option property sort
   */
  addPropertySort() {
    if (this.listSortedProperty.length >= this.listProperty.length) {
      return;
    }
    this.listSortedProperty.push('NONE');
    this.listSortedType.push(this.listSortType[0]);
    this.arraySorted.push('1');
    this.rowSelected = this.listSortedProperty?.length - 1;
  }

  changeProperty(property) {
    this.listSortedProperty[this.rowSelected] = property;
    this.listProperty.map(property => (property.isChosen = false));
    this.listSortedProperty.forEach(item => {
      let property = this.listProperty.find(it => it.property == item);
      if (property) {
        property.isChosen = true;
      }
    });
  }

  /**
   * select row
   * @param index index row select
   */
  selectRow(index: number) {
    this.rowSelected = index;
  }

  /**
   * delete row
   */
  deleteRow() {
    if (this.rowSelected == undefined) {
      return;
    }
    this.arraySorted.splice(this.rowSelected, 1);
    let property = this.listProperty.find(it => it.property == this.listSortedProperty[this.rowSelected]);
    if (property) {
      property.isChosen = false;
    }
    this.listSortedProperty.splice(this.rowSelected, 1);
    this.listSortedType.splice(this.rowSelected, 1);
    this.rowSelected = this.listSortedProperty?.length ? 0 : undefined;
  }

  /**
   * move row to previous
   */
  upRow() {
    if (this.rowSelected == 0 || this.rowSelected == undefined) {
      return;
    }
    let value = this.listSortedProperty[this.rowSelected];
    this.listSortedProperty[this.rowSelected] = this.listSortedProperty[this.rowSelected - 1];
    this.listSortedProperty[this.rowSelected - 1] = value;
    let value1 = this.listSortedType[this.rowSelected];
    this.listSortedType[this.rowSelected] = this.listSortedType[this.rowSelected - 1];
    this.listSortedType[this.rowSelected - 1] = value1;
    this.scrollSelectRow(this.rowSelected);
    this.rowSelected--;
  }

  /**
   * move row to next
   */
  downRow() {
    if (this.rowSelected >= this.arraySorted.length - 1 || this.rowSelected == undefined) {
      return;
    }
    let value = this.listSortedProperty[this.rowSelected];
    this.listSortedProperty[this.rowSelected] = this.listSortedProperty[this.rowSelected + 1];
    this.listSortedProperty[this.rowSelected + 1] = value;
    let value1 = this.listSortedType[this.rowSelected];
    this.listSortedType[this.rowSelected] = this.listSortedType[this.rowSelected + 1];
    this.listSortedType[this.rowSelected + 1] = value1;
    this.scrollSelectRow(this.rowSelected);
    this.rowSelected++;
  }

  /**
   * save custom sort
   */
  save() {
    for (let i = 0; i < this.listSortedProperty.length; i++) {
      let index = this.listSortedProperty.findIndex(property => property == 'NONE');
      if (index != -1) {
        this.listSortedProperty.splice(index, 1);
        this.listSortedType.splice(index, 1);
        i--;
      } else {
        break;
      }
    }
    this.dialogRef.close([this.listSortedProperty, this.listSortedType]);
  }

  /**
   * scroll select row
   */
  scrollSelectRow(index: number): void {
    _.get(this.customSort, `nativeElement.children[${index}]`, this.customSort).scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }
}

/**
 * export dialog data
 */
export interface DialogData {
  sortedProperty: Array<String>;
  sortedType: Array<String>;
}

/**
 * interface property
 */
export interface header {
  headerName: string;
  isChecked: string;
  property: string;
  isChosen: boolean;
}

/**
 * export dialog data
 */
export interface DialogData {
  list: [][];
}
