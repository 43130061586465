import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Constant } from 'app/config/constants';
import { APIDetails } from 'app/model/entity/api-details';
import { CommonTable } from 'app/model/entity/commonTable';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonTableService } from 'app/service/common-table.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'dialog-api-details',
  templateUrl: './dialog-api-details.component.html',
  styleUrls: ['./dialog-api-details.component.scss']
})
export class DialogApiDetailsComponent implements OnInit {
  Constant = Constant;

  /**
   * apiDetails
   */
  apiDetails: APIDetails;

  /**
   * msg key
   */
  public msgKey: any;

  /**
   * msg url
   */
  public msgURL: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogAPIDetails,
    private dialogRef: MatDialogRef<DialogApiDetailsComponent>,
    private commonTableService: CommonTableService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.apiDetails = JSON.parse(this.data.commonTable.value);
    if (this.apiDetails.url) {
      let index = this.apiDetails.url.indexOf(Constant.PARAM_OPEN_WEATHER);
      if (index != -1) {
        this.apiDetails.url = this.apiDetails.url.substring(0, index);
      }
    }
    this.msgKey = <HTMLElement>document.getElementById('msgKey');
    this.msgURL = <HTMLElement>document.getElementById('msgURL');
  }

  /**
   * on submit
   * @returns
   */
  public onSubmit(): void {
    // if (this.isValidateWhenSaveAPIDetails()) {
    //   return;
    // }
    let apiDetail = new APIDetails(this.apiDetails.apiKey, this.apiDetails.url.concat(Constant.PARAM_OPEN_WEATHER));
    this.data.commonTable.value = JSON.stringify(apiDetail);
    this.commonTableService.save(this.data.commonTable).subscribe(result => {
      this.dialogRef.close(result);
    });
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * change api key
   * @param apiKey
   */
  public changeAPIKey(apiKey: any): void {
    if (apiKey == '') {
      this.msgKey.innerHTML = this.translateService.instant('dialog-api-details.msg.api-key-empty');
    } else {
      this.msgKey.innerHTML = '';
    }
  }

  /**
   * change url
   * @param url
   */
  public changeURL(url: any): void {
    if (url == '') {
      this.msgURL.innerHTML = this.translateService.instant('dialog-api-details.msg.url-empty');
    } else {
      this.msgURL.innerHTML = '';
    }
  }

  /**
   * check validate when save api detail
   * @returns
   */
  public isValidateWhenSaveAPIDetails(): boolean {
    let isInvalid = false;
    if (!this.apiDetails.apiKey) {
      this.msgKey.innerHTML = this.translateService.instant('dialog-api-details.msg.api-key-empty');
      isInvalid = true;
    }
    if (!this.apiDetails.url) {
      this.msgURL.innerHTML = this.translateService.instant('dialog-api-details.msg.url-empty');
      isInvalid = true;
    }
    return isInvalid;
  }
}

export interface DialogAPIDetails {
  commonTable: CommonTable;
}
