import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { TemplateModeEnum } from 'app/config/constants';
import { Template } from 'app/model/entity/template';
import { TemplateService } from 'app/service/template.service';

@Component({
  selector: 'dialog-change-template-for-external-content',
  templateUrl: './dialog-change-template-for-external-content.component.html',
  styleUrls: ['./dialog-change-template-for-external-content.component.scss']
})
export class DialogChangeTemplateForExternalContentComponent implements OnInit {
  public templates: Array<Template>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogChangeTemplateForExternalContentComponent>,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.templateService.getTemplatesByGroupMode(TemplateModeEnum.EXTERNAL_CONTENT).subscribe(templatesDataResponse => {
        this.templates = Helper.convertDataTemplatesBackward(templatesDataResponse);
      });
    });
  }

  changeTemplate(templateId: any) {
    this.data.template = templateId ? this.templates.find(template => template.id == templateId) : new Template();
  }

  save() {
    this.dialogRef.close(this.data.template);
  }
}
export interface DialogData {
  template: Template;
}
