import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import moment from 'moment';
@Component({
  selector: 'dialog-change-timeline-setting',
  templateUrl: './dialog-change-timeline-setting.component.html',
  styleUrls: ['./dialog-change-timeline-setting.component.scss']
})
export class DialogChangeTimelineSettingComponent implements OnInit {
  lastMinute: number;
  lastHour: number;
  languageKey: string;
  readonly FORMAT_TIME = 'HH:mm';
  isTimeSetting: boolean;
  /**
   * config
   */
  config: any;
  commonTable: CommonTable;
  timeDateLine: any;
  helper: Helper;

  constructor(
    private dialogRef: MatDialogRef<DialogChangeTimelineSettingComponent>,
    private commonService: CommonService,
    private translateService: TranslateService,
    private commonTableService: CommonTableService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
    this.commonTable = this.data.commonTable;
    const timeDateLine = this.commonTable.value ?? Constant.TIME_DATE_LINE_DEFAULT;
    this.timeDateLine = moment(timeDateLine, Constant.FORMAT_TIME_TO_MINUTES);
    const tmpTime = moment(this.timeDateLine)
      .format(Constant.FORMAT_TIME_TO_MINUTES)
      .split(':');
    this.lastHour = parseInt(tmpTime[0]);
    this.lastMinute = parseInt(tmpTime[1]);
    this.config = {
      format: this.FORMAT_TIME,
      unSelectOnClick: false,
      hideOnOutsideClick: true,
      showTwentyFourHours: true,
      minutesInterval: 10,
      hours24Format: 'HH'
    };
  }

  /**
   * save information time date line
   */
  public save(): void {
    this.commonTable.value = JSON.stringify(
      typeof this.timeDateLine == 'object' ? moment(this.timeDateLine).format(Constant.FORMAT_TIME_TO_MINUTES) : this.timeDateLine
    );
    this.commonTableService
      .save(this.commonTable)
      .toPromise()
      .then(() => this.dialogRef.close(this.commonTable.value))
      .catch(error => Helper.handleError(error, this.translateService, this.dialogService));
  }

  /**
   * test time
   * @param data
   * @returns
   */
  testTime(data) {
    let lastHourValue, lastMinuteValue;
    lastHourValue = this.lastHour != undefined ? this.lastHour : Constant.TMP_TIME_NULL;
    lastMinuteValue = this.lastMinute != undefined ? this.lastMinute : Constant.TMP_TIME_NULL;
    let tmpTime;
    tmpTime =
      typeof data == 'object'
        ? moment(data._d)
            .format(Constant.FORMAT_TIME_TO_MINUTES)
            .split(':')
        : data.split(':');
    let currentMinute = parseInt(tmpTime[1]);
    let currentHour = parseInt(tmpTime[0]);
    let newHour, newMinute;
    if (currentHour != lastHourValue && currentMinute != lastMinuteValue) {
      newHour = lastHourValue;
      newMinute = currentMinute;
      this.timeDateLine = `${newHour.toString().padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}`;

      this.cdr.detectChanges();
    } else {
      newHour = currentHour;
      newMinute = currentMinute;
    }
    this.lastHour = newHour;
    this.lastMinute = newMinute;
  }
}

export interface DialogData {
  commonTable: CommonTable;
}
