import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/service/common.service';
import { Helper } from '../../common/helper';
import { Channel } from '../../model/entity/channel';
import { DialogService } from '../../service/dialog.service';
import { SignageChannelService } from '../../service/signage-channel.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'app-dialog-channel',
  templateUrl: './dialog-channel.component.html',
  styleUrls: ['./dialog-channel.component.scss']
})
/**
 * component class for dialog edit channel
 */
export class DialogChannelComponent implements OnInit {
  /**
   * selected channel
   */
  channel: Channel;
  @ViewChild('inputName') nameRef: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogChannelComponent>,
    private dialogService: DialogService,
    private channelService: SignageChannelService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.channel = this.data.channel;
  }

  /**
   * save the selected channel
   */
  saveChannel() {
    let channelName = this.channel.name.trim();
    if (channelName.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Channel name cannot be empty.' } }, result => {
        if (!result) {
          this.setFocus();
        }
      });
      return;
    }

    if (channelName.length > 48) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        { data: { title: 'Error', text: 'Channel name must contain no more than 48 characters.' } },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      return;
    }
    let projectId = this.commonService.getCommonObject().projectId;
    if (!projectId) {
      return;
    }
    this.channelService.getChannelsForDSCComponentByProjectId(projectId).subscribe(
      channels => {
        if (
          Helper.convertChannelsData(channels).findIndex(channel => channel.id != this.data.channel.id && channel.name == channelName) != -1
        ) {
          this.dialogService.showDialog(
            DialogMessageComponent,
            { data: { title: 'Error', text: 'Channel name already exists.' } },
            result => {
              if (!result) {
                this.setFocus();
              }
            }
          );
          return;
        } else {
          this.channel.name = channelName;
          let date = new Date();
          Helper.updateDates(this.channel, Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate()));
          this.dialogRef.close(this.channel);
        }
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * set focus textbox add, update channel
   */
  public setFocus() {
    setTimeout(() => {
      this.nameRef?.nativeElement.focus();
    }, 0);
  }
}

export interface DialogData {
  title: string;
  channel: Channel;
}
