import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { CommonService } from 'app/service/common.service';
import { Label } from '../../model/entity/label';
import { DialogService } from '../../service/dialog.service';
import { LabelService } from '../../service/label.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'app-dialog-change-label',
  templateUrl: './dialog-change-label.component.html',
  styleUrls: ['./dialog-change-label.component.scss']
})
export class DialogChangeLabelComponent implements OnInit {
  /**
   * title
   */
  title: string;
  /**
   * label list
   */
  labelList: Array<Label>;
  /**
   * label name
   */
  labelCommon: Label;
  /**
   * function id of the screen
   */
  functionId: number;

  /**
   * constructor
   * @param dialogService DialogService
   * @param labelService LabelService
   */
  constructor(
    private dialogService: DialogService,
    private labelService: LabelService,
    private dialogRef: MatDialogRef<DialogChangeLabelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    this.labelCommon = data.labelCommon;
    this.title = data.title;
    this.functionId = data.functionId;
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.getAllLabel();
  }

  /**
   * fetch all label
   */
  private getAllLabel(): void {
    this.labelService.getLabels(this.functionId).subscribe(
      labels => {
        this.labelList = labels;
      },
      error => {
        this.dialogRef.close();
        this.handleError();
      }
    );
  }

  /**
   * change label
   */
  public changeLabel(value: Number): void {
    let label = this.labelList.find(label => label.id == value);
    this.labelCommon = label ? label : null;
  }

  /**
   *
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  public keyDown(e): void {
    if (e.keyCode == 27) {
      this.dialogRef.close(false);
    }
  }

  /**
   * save label
   */
  public async saveLabel(): Promise<void> {
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return;
    }
    this.dialogRef.close(this.labelCommon);
  }

  /**
   * handle error
   *
   */
  private handleError() {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('dialog-error.msg')
      }
    });
  }
}

export interface DialogData {
  functionId: number;
  labelCommon: Label;
  title: string;
  screen: string;
}
