import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { DisplaysEnum, ScreenNameEnum, TemplateModeEnum, TemplateTypeEnum } from 'app/config/constants';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { Template } from 'app/model/entity/template';
import { TemplateService } from 'app/service/template.service';

@Component({
  selector: 'app-dialog-change-template-for-display',
  templateUrl: './dialog-change-template-for-display.component.html',
  styleUrls: ['./dialog-change-template-for-display.component.scss']
})
/**
 * Class Dialog change template display
 */
export class DialogChangeTemplateForDisplayComponent implements OnInit {
  TemplateTypeEnum = TemplateTypeEnum;
  templateGroupMode: TemplateModeEnum;

  labelDisplay: string = 'Display';
  templates: Template[];
  templatesMainPage: Template[];
  templatesSubPage1: Template[];
  templatesSubPage2: Template[];
  templatesSubPage3: Template[];
  templatesSubPage4: Template[];
  templatesSubPage5: Template[];
  templatesSubPage6: Template[];
  templatesSubPage7: Template[];
  templatesSubPage8: Template[];
  templatesSubPage9: Template[];
  displayTemplate: DisplayTemplate;
  isNoSelectedMainPage1: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogChangeTemplateForDisplayComponent>,
    private templateService: TemplateService
  ) {
    this.labelDisplay = data.display == DisplaysEnum.DISPLAY_1 ? 'Display 1' : 'Display 2';
    this.displayTemplate = data.displayTemplate ?? null;
    this.isNoSelectedMainPage1 = !this.displayTemplate?.idMainPage;
  }

  ngOnInit() {
    switch (this.data.screen) {
      case ScreenNameEnum.ON_BUS_DISPLAY:
        this.templateGroupMode = TemplateModeEnum.ON_BUS_DISPLAY;
        break;
      case ScreenNameEnum.STATION_DISPLAY:
        this.templateGroupMode = TemplateModeEnum.STATION_DISPLAY;
        break;
      case ScreenNameEnum.SIGNAGE_DISPLAY:
        this.templateGroupMode = TemplateModeEnum.SIGNAGE_DISPLAY;
        break;
      default:
        break;
    }
    this.fetchAllTemplate(this.templateGroupMode);
  }

  /**
   * fetch data all template
   * @param templateGroupMode
   */
  fetchAllTemplate(templateGroupMode: TemplateModeEnum): void {
    this.templateService.getTemplatesByGroupMode(templateGroupMode).subscribe(data => {
      this.templates = Helper.convertDataTemplatesBackward(data);
      this.templatesMainPage = this.templates.filter(template => template.templateType == TemplateTypeEnum.MAIN);
      this.templatesSubPage1 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_1);
      this.templatesSubPage2 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_2);
      this.templatesSubPage3 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_3);
      this.templatesSubPage4 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_4);
      this.templatesSubPage5 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_5);
      this.templatesSubPage6 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_6);
      this.templatesSubPage7 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_7);
      this.templatesSubPage8 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_8);
      this.templatesSubPage9 = this.templates.filter(template => template.templateType == TemplateTypeEnum.SUB_PAGE_9);
    });
  }

  /**
   * be called when select template on select box
   * @param id id's template
   * @param templateType template type: main, sub1->4
   */
  changeTemplateDisplay(id: Number, templateType: TemplateTypeEnum): void {
    switch (templateType) {
      case TemplateTypeEnum.MAIN:
        this.displayTemplate.idMainPage = id == -1 ? null : id;
        this.displayTemplate = id == -1 ? new DisplayTemplate() : this.displayTemplate;
        this.isNoSelectedMainPage1 = id == -1;
        break;
      case TemplateTypeEnum.SUB_PAGE_1:
        this.displayTemplate.idSubPage1 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_2:
        this.displayTemplate.idSubPage2 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_3:
        this.displayTemplate.idSubPage3 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_4:
        this.displayTemplate.idSubPage4 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_5:
        this.displayTemplate.idSubPage5 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_6:
        this.displayTemplate.idSubPage6 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_7:
        this.displayTemplate.idSubPage7 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_8:
        this.displayTemplate.idSubPage8 = id == -1 ? null : id;
        break;
      case TemplateTypeEnum.SUB_PAGE_9:
        this.displayTemplate.idSubPage9 = id == -1 ? null : id;
        break;
      default:
        break;
    }
  }

  /**
   * Save change template from dialog
   */
  save(): void {
    this.dialogRef.close(this.displayTemplate);
  }
}

export interface DialogData {
  display: DisplaysEnum;
  displayTemplate: DisplayTemplate;
  screen: ScreenNameEnum;
}
