import { Helper } from 'app/common/helper';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'app/service/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleOperationManagerService } from 'app/service/schedule-operation-maganer.service';

@Component({
  selector: 'dialog-area-item-setting',
  templateUrl: './dialog-area-item-setting.component.html',
  styleUrls: ['./dialog-area-item-setting.component.scss']
})
export class DialogAreaItemSettingComponent implements OnInit {
  public categoryId: number;
  public listCategory: any;
  public listItemDetail: ItemDetail[];
  public listItemChoosed: any;
  public languageKey: string;
  public itemTitle: string;
  public value: any;
  constructor(
    private dialogRef: MatDialogRef<DialogAreaItemSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private scheduleOperationManagerService: ScheduleOperationManagerService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.listCategory = this.data.headerCRUDs;
      this.languageKey = this.data.languageKey;
      this.value = this.data.value;
    }
    this.getListItemDetail();
    this.checkDisableLabel();
    this.itemTitle = this.languageKey == 'en' ? 'Item' : '項目';
  }

  /**
   * getListItemDetail
   */
  private getListItemDetail() {
    this.listItemDetail = this.value;
    if (!this.listItemDetail || this.listItemDetail.length == 0) {
      this.listItemDetail = [
        {
          label: '',
          value: ''
        }
      ];
    }
  }

  /**
   * save
   */
  public save(): void {
    let dataSave = this.listItemDetail.filter(e => e.label);
    if (dataSave.length == 0) {
      dataSave = [
        {
          label: '',
          value: ''
        }
      ];
    }
    this.scheduleOperationManagerService.saveAreaItemSetting(JSON.stringify(dataSave), this.data.areaGroup).subscribe(
      () => {
        this.dialogRef.close(JSON.stringify(dataSave));
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close(undefined);
  }

  /**
   * addNewItem
   */
  public addNewItem() {
    this.listItemDetail.push({
      label: '',
      value: ''
    });
  }

  /**
   * checkMaxItem
   * @returns
   */
  public checkMaxItem() {
    if (this.listItemDetail?.length == 5) {
      return true;
    }
    return false;
  }

  /**
   * checkDisableLabel
   */
  public checkDisableLabel() {
    this.listItemChoosed = [];
    this.listItemDetail.forEach(e => {
      this.listItemChoosed.push(e.label);
    });
  }

  /**
   * checkChangeLabel
   */
  public checkChangeLabel() {
    this.listItemDetail.forEach(e => {
      if (!e.label) {
        e.value = '';
      }
    });
  }
}

export interface ItemDetail {
  label: string;
  value: string;
}
