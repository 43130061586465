import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { ObjectFitEnum } from '../../config/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sequence } from '../../model/entity/sequence';
import { Constant } from 'app/config/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationFormService } from 'app/service/validation-form.service';
import { Media } from 'app/model/entity/media';
import { Helper } from 'app/common/helper';

@Component({
  selector: 'app-dialog-add-sequence',
  templateUrl: './dialog-add-sequence.component.html',
  styleUrls: ['./dialog-add-sequence.component.scss']
})
export class DialogAddSequenceComponent implements OnInit {
  /**
   * ElementRef sequence name
   */
  @ViewChild('sequenceName') private elementRefName: ElementRef;
  Constant = Constant;
  ObjectFitEnum = ObjectFitEnum;
  addSequenceForm: FormGroup;
  submitted: boolean = false;
  propertyName: { [key: string]: string } = {
    name: 'Sequence name',
    fileName: 'File name',
    width: 'Sequence width',
    height: 'Sequence height'
  };
  constructor(
    public dialogRef: MatDialogRef<DialogAddSequenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }
  /**
   * create form
   */
  private createForm() {
    this.addSequenceForm = this.formBuilder.group({
      name: [
        this.data.sequence.name,
        [
          this.validationFormService.invalidEmpty,
          this.validationFormService.invalidSpecialCharacter,
          Validators.maxLength(Constant.MAX_LENGTH_TEMPLATE_GROUP_NAME)
        ]
      ],
      fileName: [this.data.sequence.name, [this.validationFormService.existMediaName(this.data.mediaList)]],
      width: [
        this.data.sequence.width,
        [this.validationFormService.invalidEmpty, Validators.min(Constant.MIN_TEMPLATE_SIZE), Validators.max(Constant.MAX_TEMPLATE_SIZE)]
      ],
      height: [
        this.data.sequence.height,
        [this.validationFormService.invalidEmpty, Validators.min(Constant.MIN_TEMPLATE_SIZE), Validators.max(Constant.MAX_TEMPLATE_SIZE)]
      ]
    });
  }
  /**
   * changeResizeSequence
   * @param resize
   */
  changeResizeSequence(resize) {
    this.data.sequence.objectFit = resize;
  }
  /**
   * save data
   */
  saveData() {
    this.data.sequence.name = this.addSequenceForm.value.name;
    this.data.sequence.width = this.addSequenceForm.value.width;
    this.data.sequence.height = this.addSequenceForm.value.height;
    this.createForm();
    this.submitted = true;
    if (this.addSequenceForm.valid) {
      let sequenceNew = new Sequence(
        this.addSequenceForm.value.name.trim(),
        this.addSequenceForm.value.width,
        this.addSequenceForm.value.height
      );
      sequenceNew.objectFit = this.data.sequence.objectFit;
      this.dialogRef.close(sequenceNew);
    }
  }
  /**
   * handling Decimal
   */
  handlingDecimal(inputNumber: number, input: any) {
    input.value = Helper.handlingDecimal(inputNumber);
  }
}

export interface DialogData {
  title: string;
  sequence: Sequence;
  mediaList: Array<Media>;
}
