import { Helper } from 'app/common/helper';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonTable } from 'app/model/entity/commonTable';
import { CommonTableService } from 'app/service/common-table.service';
import { Constant } from 'app/config/constants';
import { DialogService } from 'app/service/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomTagService } from 'app/service/custom-tag.service';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DataService } from 'app/service/data.service';

@Component({
  selector: 'dialog-area-setting',
  templateUrl: './dialog-area-setting.component.html',
  styleUrls: ['./dialog-area-setting.component.scss']
})
export class DialogAreaSettingComponent implements OnInit {
  public areaGroupId: number;
  listAreaGroup: any;
  commonTable: CommonTable;

  constructor(
    private dialogRef: MatDialogRef<DialogAreaSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSwitchAreaData,
    private customTagService: CustomTagService,
    private dialogService: DialogService,
    private commonTableService: CommonTableService,
    private translateService: TranslateService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.commonTable = this.data.commonTable;
    this.areaGroupId = +this.data.commonTable.value;
    this.getListAreaGroup();
  }

  getListAreaGroup() {
    this.customTagService.getCustomTag().subscribe(
      data => {
        this.listAreaGroup = data[2].elements;
      },
      error => {
        this.dialogRef.close();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * save
   */
  public save(): void {
    this.commonTable.value = this.areaGroupId.toString();
    this.commonTableService.save(this.commonTable).subscribe(
      () => {
        this.dataService.sendData([Constant.HAS_AREA, this.areaGroupId == 0 ? false : true]);
        this.dialogRef.close(this.commonTable.value);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * close dialog
   */
  public closeDialog(): void {
    this.dialogRef.close('cancel');
  }
}

export interface DialogSwitchAreaData {
  commonTable: CommonTable;
}
