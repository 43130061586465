import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper as Helper2 } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { UserService } from 'app/core/user/user.service';
import { Helper } from 'app/layouts/helper';
import { ChangePassword } from 'app/model/entity/change-password';
import { DialogService } from 'app/service/dialog.service';
import { AppState } from 'app/store/app.state';
import { ToastrService } from 'ngx-toastr';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-change-password',
  templateUrl: './dialog-change-password.component.html',
  styleUrls: ['./dialog-change-password.component.scss']
})
export class DialogChangePasswordComponent implements OnInit {
  @ViewChild('btnSaveChangePassword') changePassword: ElementRef;
  /**
   * current password
   */
  currentPassword: string = '';
  /**
   * new password
   */
  newPassword: string = '';
  /**
   * confirm password
   */
  confirmedPassword: string = '';
  constructor(
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<DialogChangePasswordComponent>,
    private userService: UserService,
    private toast: ToastrService,
    public readonly store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  /**
   * save change password
   */
  public async saveChangePassword(): Promise<void> {
    const minLengthPassword = 8;
    const maxLengthPassword = 16;

    const changePassword = new ChangePassword(this.currentPassword, this.newPassword);
    // check confirm current password
    let errorText = await this.userService
      .checkConfirmCurrentPassword(changePassword)
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
        if (error.error.errorKey == Constant.ERROR_KEY_USER_NOT_EXISTS) {
          return error.error.errorKey;
        }
        return error.error.text;
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return;
    }
    if (errorText == Constant.ERROR_KEY_USER_NOT_EXISTS) {
      Helper.handleErrorUserNotExists(this.dialogService, this.store, this.translateService);
      return;
    }
    if (errorText) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-change-password.msg.title-error'),
            text: this.translateService.instant('dialog-change-password.msg.current-password-invalid')
          }
        },
        () => {
          this.changePassword.nativeElement.blur();
        }
      );
      return;
    }
    // validate password
    if (this.newPassword.length == 0) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-change-password.msg.title-error'),
            text: this.translateService.instant('dialog-change-password.msg.new-password-empty')
          }
        },
        () => {
          this.changePassword.nativeElement.blur();
        }
      );
      return;
    }
    if (this.newPassword.length < minLengthPassword) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-change-password.msg.title-error'),
            text: Helper2.formatString(
              this.translateService.instant('dialog-change-password.msg.new-password-min'),
              `${minLengthPassword}`,
              `${maxLengthPassword}`
            )
          }
        },
        () => {
          this.changePassword.nativeElement.blur();
        }
      );
      return;
    }
    if (this.newPassword.length > maxLengthPassword) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-change-password.msg.title-error'),
            text: Helper2.formatString(
              this.translateService.instant('dialog-change-password.msg.new-password-max'),
              `${minLengthPassword}`,
              `${maxLengthPassword}`
            )
          }
        },
        () => {
          this.changePassword.nativeElement.blur();
        }
      );
      return;
    }
    if (!this.newPassword.match(Constant.FORMAT_PASSWORD_REGEX)) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-change-password.msg.title-error'),
            text: this.translateService.instant('dialog-change-password.msg.password-invalid')
          }
        },
        () => {
          this.changePassword.nativeElement.blur();
        }
      );
      return;
    }
    if (this.newPassword != this.confirmedPassword) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('dialog-change-password.msg.title-error'),
            text: this.translateService.instant('dialog-change-password.msg.password-not-match')
          }
        },
        () => {
          this.changePassword.nativeElement.blur();
        }
      );
      return;
    }
    // change password
    this.userService.changePassword(changePassword).subscribe(
      () => {
        this.toast.success(this.translateService.instant('dialog-change-password.msg.change-password-success'), '');
        this.dialogRef.close();
      },
      error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.error-network-api')
            }
          });
          return;
        }
        if (error.error.errorKey == Constant.ERROR_KEY_USER_NOT_EXISTS) {
          Helper.handleErrorUserNotExists(this.dialogService, this.store, this.translateService);
          return;
        }
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-change-password.msg.title-error'),
              text: this.translateService.instant('dialog-change-password.msg.current-password-invalid')
            }
          },
          () => {
            this.changePassword.nativeElement.blur();
          }
        );
      }
    );
  }
}
