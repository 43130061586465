import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  ErrorCodeAnnouncementMedia,
  ErrorCodeSimpleMedia,
  ErrorValidate,
  PlaylistType,
  TypeMediasNotSupported
} from 'app/config/constants';
import { AnnouncementMedia } from 'app/model/entity/announcement/announcement-media';
import { MessageErrorTabTranslation } from 'app/model/entity/announcement/message-error-tab-translation';
import { PlayListTranslation } from 'app/model/entity/announcement/playlist-translation';
import { SpeechSynthesis } from 'app/model/entity/speech-synthesis';
import { AnnouncementManagerService } from 'app/service/announcement/announcement-manager.service';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-create-playlist-tab-translation-speech-synthesis',
  templateUrl: './dialog-create-playlist-tab-translation-speech-synthesis.component.html',
  styleUrls: ['./dialog-create-playlist-tab-translation-speech-synthesis.component.scss']
})
export class DialogCreatePlaylistTabTranslationSpeechSynthesisComponent implements OnInit {
  @ViewChild('fileName') fileNameElementRef: ElementRef;
  @ViewChild('playlistName') playlistNameElementRef: ElementRef;
  isCreatePlaylist: boolean = false; // return true if isCreatePlaylist
  PlaylistType = PlaylistType; // type Playlist
  createPLaylistForm: FormGroup; // form Create Playlist
  isSubmitted: boolean = false; // return true if submitt
  isSubmittedCreatePlaylist: boolean = false; // return true if submitt when isCreatePlaylist
  errorValidateFileName: ErrorValidate; // errorValidateFileName
  errorValidatePlaylistName: ErrorValidate; // errorValidatePlaylistName
  ErrorValidate = ErrorValidate; // type ErrorValidate
  isDuplicateFileName: boolean; //return true if  Duplicate File Name
  isDuplicatePlaylistName: boolean; //return true if  Duplicate Playlist Name
  fileNameDuplicateCloneDepp: string; // fileNameDuplicateCloneDepp
  playlistNameDuplicateCloneDeep: string; // fileNameDuplicateCloneDepp

  /**
   * Error message Tab Translation
   */
  private messageErrorTabTranslation: MessageErrorTabTranslation;
  constructor(
    private announcementManagerService: AnnouncementManagerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPlaylistTranslation,
    private dialogRef: MatDialogRef<DialogCreatePlaylistTabTranslationSpeechSynthesisComponent>,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initFormCreatePlaylist();
  }

  /**
   * save Playlist
   */
  async savePlaylist(): Promise<void> {
    this.isSubmitted = true;
    if (this.isCreatePlaylist == true) {
      this.isSubmittedCreatePlaylist = true;
    }
    this.validateFormCreatePlayList();
    if (this.createPLaylistForm.valid && this.data && this.data.listSpeechSynthesisFilter) {
      const speechSynthesis = this.data.listSpeechSynthesisFilter.map(item => {
        let languageName = this.data.languageKey == 'en' ? item.languageNameEn : item.languageNameJa;
        if (languageName && languageName.length) {
          languageName = _.cloneDeep(languageName.replace(/ \(/g, '（').replace(/\)/g, '）'));
        }
        const pollyLanguageCode = item.pollyLanguageCode + '.mp3';
        return { languageName, pollyLanguageCode };
      });

      let playListTranslation = this.getDataFormFrom();
      playListTranslation.speechSynthesis = speechSynthesis;

      let mediaFromDB: any[] = [];
      let fileNames: string[] = [];
      await this.announcementManagerService
        .getAllMediaFromDB()
        .toPromise()
        .then(async data => {
          mediaFromDB = Helper.convertDataAnnouncementMediasNotContainDate(data, true).filter(
            media => !TypeMediasNotSupported.includes(media.type)
          );
        });

      for (let files of playListTranslation.speechSynthesis) {
        const file = playListTranslation.fileName + '_' + files.languageName;
        fileNames.push(file);
      }

      // check duplicate file name when save audio
      let mediaNames = mediaFromDB.map(media => media.name);
      for (const mediaName of fileNames) {
        if (mediaNames.includes(mediaName)) {
          this.isDuplicateFileName = true;
          this.fileNameDuplicateCloneDepp = _.cloneDeep(playListTranslation.fileName);
          return;
        }
      }
      this.isDuplicateFileName = false;
      if (playListTranslation.isCreatePlaylist) {
        this.announcementManagerService.checkExistPlaylistTabTranslation(playListTranslation.playlistName).subscribe(res => {
          if (res) {
            this.isDuplicatePlaylistName = true;
            this.playlistNameDuplicateCloneDeep = _.cloneDeep(playListTranslation.playlistName);
          } else {
            this.isDuplicatePlaylistName = false;
            this.savePlaylistTranslation(playListTranslation);
          }
        });
      } else {
        this.savePlaylistTranslation(playListTranslation);
      }
    }
  }

  /**
   * savePlaylistTranslation
   * @returns
   */
  savePlaylistTranslation(playListTranslation: PlayListTranslation): void {
    this.announcementManagerService.savePlaylistTranslation(playListTranslation).subscribe(
      res => {
        if (!res) {
          this.dialogRef.close(true);
          return;
        }
        let errorMessageTabTranslation = this.getErrorMessageFromBackWardTabTranslation(res, playListTranslation.fileName);
        if (!errorMessageTabTranslation.isValid) {
          this.messageErrorTabTranslation = errorMessageTabTranslation;
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('announcement-manager.error'),
              validateTabTranslation: this.messageErrorTabTranslation
            }
          });
          return;
        }
      },
      error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.error-network')
            }
          });
          return;
        }
      }
    );
  }

  /**
   * Get error message from backward
   *
   * @param data
   * @param file
   * @returns
   */
  private getErrorMessageFromBackWardTabTranslation(data: Array<AnnouncementMedia>, file: any): MessageErrorTabTranslation {
    let messageErrorTabTranslation = new MessageErrorTabTranslation(null, false, false, false, null, true);
    data.forEach((media: AnnouncementMedia) => {
      switch (media.id) {
        case ErrorCodeAnnouncementMedia.ERROR_MAX_LENGTH_MEDIA_CODE:
          messageErrorTabTranslation.isMaxLengthError = true;
          messageErrorTabTranslation.isValid = this.calculateIsValid(messageErrorTabTranslation);
          break;

        case ErrorCodeAnnouncementMedia.ERROR_DUPLICATE_MEDIA_NAME:
          messageErrorTabTranslation.isMaxLengthError = true;
          messageErrorTabTranslation.isValid = this.calculateIsValid(messageErrorTabTranslation);
          break;

        case ErrorCodeSimpleMedia.ERROR_OTHER_CODE:
          messageErrorTabTranslation.otherError = file[Constant.NAME_ELEMENT];
          messageErrorTabTranslation.isValid = this.calculateIsValid(messageErrorTabTranslation);
          break;
        default:
          break;
      }
    });
    return messageErrorTabTranslation;
  }

  /**
   * calculate IsValid
   */
  calculateIsValid(messageErrorTabTranslation: MessageErrorTabTranslation): boolean {
    return (
      !messageErrorTabTranslation.formatErrorFile?.length &&
      !messageErrorTabTranslation.isSpecialCharError &&
      !messageErrorTabTranslation.isMaxLengthError &&
      !messageErrorTabTranslation.isDuplicate &&
      !messageErrorTabTranslation.otherError?.length
    );
  }

  /**
   * change data
   */
  public changeData(): void {
    this.isCreatePlaylist = !this.isCreatePlaylist;
    if (this.isDuplicatePlaylistName) {
      this.isDuplicatePlaylistName = false;
    }
    if (!this.isCreatePlaylist) {
      this.createPLaylistForm.patchValue({
        playlistName: ''
      });
    } else {
      this.createPLaylistForm.patchValue({
        playlistName: this.createPLaylistForm.value?.fileName ? this.createPLaylistForm.value?.fileName : ''
      });
      this.onKeyPlaylistName();
    }
  }

  /**
   * initFormCreatePlaylist
   */
  initFormCreatePlaylist(): void {
    this.createPLaylistForm = this.fb.group({
      fileName: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constant.MAX_LENGTH_FILE_NAME_TAB_TRANSLATION),
          Validators.pattern(Constant.REGEX_CONTAINS_SPECIAL_CHARACTERS_TAB_TRANSLATION),
          this.noWhitespaceValidator
        ]
      ],
      isCreatePlaylist: [false],
      playlistType: [PlaylistType.COMMON],
      playlistName: ['']
    });
    this.createPLaylistForm.get('isCreatePlaylist').valueChanges.subscribe(() => {
      this.updatePlaylistNameValidators();
    });
  }

  /**
   * updatePlaylistNameValidators
   */
  updatePlaylistNameValidators(): void {
    const playlistNameControl = this.createPLaylistForm.get('playlistName');
    if (this.createPLaylistForm.get('isCreatePlaylist').value === true) {
      playlistNameControl.setValidators([
        Validators.required,
        Validators.maxLength(Constant.MAX_LENGTH_NAME_PLAYLIST),
        Validators.pattern(Constant.REGEX_CONTAINS_SPECIAL_CHARACTERS_TAB_TRANSLATION_PLAYLIST_NAME),
        this.noWhitespaceValidator
      ]);
    } else {
      playlistNameControl.clearValidators();
    }

    playlistNameControl.updateValueAndValidity();
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  /**
   * get form Control
   */
  get formControl() {
    return this.createPLaylistForm.controls;
  }

  /**
   * validate Form Create PlayList
   * @returns
   */
  validateFormCreatePlayList(): void {
    if (this.createPLaylistForm.get('fileName').errors) {
      if (
        this.createPLaylistForm.get('fileName').errors?.required ||
        this.createPLaylistForm.get('fileName').errors?.whitespace ||
        this.createPLaylistForm.get('fileName').errors?.maxlength
      ) {
        this.errorValidateFileName = ErrorValidate.MAX_LENGTH;
      } else if (this.createPLaylistForm.get('fileName').errors?.pattern) {
        this.errorValidateFileName = ErrorValidate.SPECIAL_CHARACTERS;
      }
    }

    if (this.createPLaylistForm.get('playlistName').errors) {
      if (
        this.createPLaylistForm.get('playlistName').errors?.required ||
        this.createPLaylistForm.get('playlistName').errors?.whitespace ||
        this.createPLaylistForm.get('playlistName').errors?.maxlength
      ) {
        this.errorValidatePlaylistName = ErrorValidate.MAX_LENGTH;
      } else if (this.createPLaylistForm.get('playlistName').errors?.pattern) {
        this.errorValidatePlaylistName = ErrorValidate.SPECIAL_CHARACTERS;
      }
    }

    if (this.createPLaylistForm.get('fileName').errors) {
      this.fileNameElementRef.nativeElement.focus();
    } else if (this.createPLaylistForm.get('playlistName').errors) {
      this.playlistNameElementRef.nativeElement.focus();
    }
  }

  /**
   * getDataFormFrom
   *
   * @returns
   */
  getDataFormFrom(): PlayListTranslation {
    const createPLaylistFormValue = this.createPLaylistForm.value;
    let playListTranslation = new PlayListTranslation();
    playListTranslation.fileName = createPLaylistFormValue.fileName;
    playListTranslation.isCreatePlaylist = createPLaylistFormValue.isCreatePlaylist;
    if (createPLaylistFormValue.isCreatePlaylist) {
      playListTranslation.playlistType = createPLaylistFormValue.playlistType;
      playListTranslation.playlistName = createPLaylistFormValue.playlistName;
    }
    return playListTranslation;
  }

  /**
   * onKeyFileName
   */
  onKeyFileName(): void {
    this.isDuplicateFileName =
      this.fileNameDuplicateCloneDepp && this.createPLaylistForm.value?.fileName.trim() === this.fileNameDuplicateCloneDepp;
  }

  /**
   * onKeyPlaylistName
   */
  onKeyPlaylistName(): void {
    this.isDuplicatePlaylistName =
      this.playlistNameDuplicateCloneDeep && this.createPLaylistForm.value?.playlistName.trim() === this.playlistNameDuplicateCloneDeep;
  }
}

export interface DialogDataPlaylistTranslation {
  listSpeechSynthesisFilter: Array<SpeechSynthesis>;
  languageKey: string;
}
