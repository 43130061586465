import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisplayIndexEnum } from 'app/config/constants';
import { TemplateLED } from 'app/model/entity/destination/template-led';
import { DialogService } from 'app/service/dialog.service';
import { TemplateLedService } from 'app/service/template-led.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import _ from 'lodash';

@Component({
  selector: 'dialog-change-template-for-destination',
  templateUrl: './dialog-change-template-for-destination.component.html',
  styleUrls: ['./dialog-change-template-for-destination.component.scss']
})
export class DialogChangeTemplateForDestinationComponent implements OnInit {
  /**
   * List Template
   */
  public templates: Array<TemplateLED>;
  /**
   * template data
   */
  public templateData: DialogData;
  /**
   * Display index enum
   */
  public DisplayIndexEnum = DisplayIndexEnum;

  constructor(
    public dialogRef: MatDialogRef<DialogChangeTemplateForDestinationComponent>,
    private templateLedService: TemplateLedService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService
  ) {
    this.templateData = data;
  }

  ngOnInit(): void {
    this.fetchAllData();
  }

  private fetchAllData(): void {
    this.templateLedService.getAllTemplate().subscribe(templates => {
      this.templates = templates;
    });
  }

  /**
   * Change templates display
   *
   * @param value
   * @param index
   */
  public changeTemplatesDisplay(value: any, index: number): void {
    this.templateData[`display${index}`] = _.cloneDeep(this.templates.find(template => template.id == value));
  }

  /**
   * Save template
   */
  public saveTemplate(): void {
    if (
      !this.templateData.display1 &&
      !this.templateData.display2 &&
      !this.templateData.display3 &&
      !this.templateData.display4 &&
      !this.templateData.display5 &&
      !this.templateData.display6
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select at least 1 template.`
        }
      });
      return;
    }
    this.dialogRef.close(this.templateData);
  }
}

export interface DialogData {
  display1: TemplateLED;
  display2: TemplateLED;
  display3: TemplateLED;
  display4: TemplateLED;
  display5: TemplateLED;
  display6: TemplateLED;
}
