import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'app/service/dialog.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-confirm-delete-app',
  templateUrl: './dialog-confirm-delete-app.component.html',
  styleUrls: ['./dialog-confirm-delete-app.component.scss']
})
export class DialogConfirmDeleteAppComponent implements OnInit {
  textConfirm: string;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  isConfirm(): void {
    if (this.textConfirm !== this.data.appName) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.msg.incorrectly-entered-app-name')
        }
      });
    } else {
      this.dialogRef.close(true);
    }
  }
}

export interface DialogData {
  text: string;
  button1: string;
  button2: string;
  title?: string;
  appName: string;
}
