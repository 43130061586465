import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { TranslateService } from '@ngx-translate/core';
import { CommonTableService } from 'app/service/common-table.service';
import _ from 'lodash';
@Component({
  selector: 'dialog-auto-update-playlist',
  templateUrl: './dialog-auto-update-playlist.component.html',
  styleUrls: ['./dialog-auto-update-playlist.component.scss']
})
export class DialogAutoUpdatePlaylistComponent implements OnInit {
  /**
   * Object autoUpdate
   */
  public autoUpdateObject: any;

  /**
   * errorTimeAutoRefresh
   */
  public errorTimeAutoRefresh: string;

  /**
   * MIN_TIME_AUTO_FRESH
   */
  public readonly MIN_TIME_AUTO_FRESH = 1;

  /**
   * MAX_TIME_AUTO_FRESH
   */
  public readonly MAX_TIME_AUTO_FRESH = 60;

  /**
   * DEFAULT_TIME_AUTO_FRESH
   */
  public readonly DEFAULT_TIME_AUTO_FRESH = 10;

  /**
   * constructor
   * @param dialogRef
   * @param data
   */
  constructor(
    public dialogRef: MatDialogRef<DialogAutoUpdatePlaylistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translateService: TranslateService,
    private commonTableService: CommonTableService
  ) {}

  ngOnInit(): void {
    this.autoUpdateObject = this.data ? _.cloneDeep(this.data) : { autoRefresh: false, timeAutoRefresh: 0 };
  }
  /**
   * save information setting device auto refresh
   */
  private saveInformationSettingAutoRefresh(): void {
    let commonSave: any = { key: Constant.KEY_AUTO_UPDATE_PLAYLIST };
    commonSave.value = JSON.stringify(this.autoUpdateObject);
    this.commonTableService.save(commonSave).subscribe(data => {
      this.dialogRef.close(data.value);
    });
  }

  /**
   * validate character input number
   * @param event
   */
  public validateCharacterNumber(event): void {
    if (Constant.LIST_ABORT_TYPE_NUMBER.includes(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * toggle auto refresh
   */
  public toggleAutoRefresh(): void {
    this.autoUpdateObject.autoRefresh = !this.autoUpdateObject.autoRefresh;
    if (this.autoUpdateObject.autoRefresh) {
      this.autoUpdateObject.timeAutoRefresh = this.DEFAULT_TIME_AUTO_FRESH;
    }
  }

  /**
   * validate information setting
   */
  private validateInformationSetting(): boolean {
    if (!this.autoUpdateObject.autoRefresh) {
      return true;
    }
    // validate time auto refresh
    if (this.autoUpdateObject.timeAutoRefresh == null) {
      this.errorTimeAutoRefresh = this.translateService.instant('dialog-setting-time-dashboard.message.time-empty');
    } else if (
      this.autoUpdateObject.timeAutoRefresh < this.MIN_TIME_AUTO_FRESH ||
      this.autoUpdateObject.timeAutoRefresh > this.MAX_TIME_AUTO_FRESH
    ) {
      this.errorTimeAutoRefresh = this.translateService.instant('dialog-auto-update-playlist.message.time-between');
    } else {
      this.errorTimeAutoRefresh = null;
    }
    return !this.errorTimeAutoRefresh;
  }

  /**
   * save data setting
   */
  public saveInformationSetting(): void {
    // validate information
    if (!this.validateInformationSetting()) {
      return;
    }
    // save information
    this.saveInformationSettingAutoRefresh();
  }

  /**
   * handlingDecimal
   * @param element
   */
  public handlingDecimal(element: any): void {
    element.value = Helper.handlingDecimal(element.value);
  }
}

export interface DialogData {
  autoUpdateObject: any;
}
